.selectable-table {
    .ant-table table {
        border-collapse: collapse;
        border-radius: 8px;
        overflow: hidden;
    }
    .ant-table-tbody {
        font-size: 13px;
    }
    .ant-table-tbody>tr.ant-table-row:hover>td {
        background: @light-yellow !important;
        cursor: pointer;
    }
    .ant-table-thead {
        .ant-table-cell {
            font-weight: 800;
            background-color: @black10 !important;
            color: @pure-black;
            &:not(.last-column) {
                border-right: 3px solid @pure-white !important;
            }
            .anticon:not(.active) {
                color: @pure-black;
            }
        }
        .ant-table-column-sorters {
            width: 100%;
            display: flex;
            justify-content: space-between;
        }
    }
    .ant-table-column-sort {
        background-color: @pure-white !important;
    }
    .ant-table-row-selected {
        .ant-table-cell {
            background-color: @pure-white !important;
            border-bottom-color: #f0f0f0;
        }
    }
	.ant-table-row-level-1 {
		background-color:  #fafafa !important;
	}
	.ant-table-cell {
		word-break: break-word !important;
	}
}

.unselectable-table {
    .ant-table table {
        border-collapse: collapse;
        border-radius: 8px;
        overflow: hidden;
    }
    .ant-table-tbody {
        font-size: 13px;
    }
    .ant-table-tbody>tr.ant-table-row:hover>td {
        background: @light-yellow !important;
    }
    .ant-table-thead {
        .ant-table-cell {
            font-weight: 800;
            background-color: @black10 !important;
            color: @pure-black;
            &:not(.last-column) {
                border-right: 3px solid @pure-white !important;
            }
            .anticon:not(.active) {
                color: @pure-black;
            }
        }
        .ant-table-column-sorters {
            width: 100%;
            display: flex;
            justify-content: space-between;
        }
    }
    .ant-table-cell {
        background-color: @pure-white !important;
        word-break: break-word !important;
    }
}

.ant-table {
    background: white !important;
    border: 10px solid white !important;
    border-radius: 8px !important;
}