.permission-form {
	margin-bottom: 30px;
	
	.ant-table-tbody {
		font-size: 13px;
	}

	thead.ant-table-thead > tr > th {
			background: none;
			color:@grey;
			font-weight: normal;
			padding:5px 16px;
	}

	.ant-table-title {
		background:@orange30;
		color:@black;
		font-size: 16px;
		font-weight: 300;
		height: 32px;
    	line-height: 16px;
	}
	.ant-table-footer {
		background: @pure-white;
		padding:5px 0px 5px 14px;
	}
	.ant-table-tbody > tr > td {
		border:none;
		padding:5px 0px 5px 16px;
		line-height: 20px;
	}
	.ant-empty-image{display: none;}
	.empty-text {
		font-size: 16px;
		font-weight: 500;
		color:@grey;
	}
}

#result-table {	
	a {
		color: @pure-black;
		&:hover {
			color: @main-yellow;
		}
	}
}

  