.directory-list {
	.ant-table table {
        border-collapse: collapse;
        border-radius: 8px 8px 0px 0px;
        overflow: hidden;
    }
	.ant-row > span {
	   font-size: 24px;
	   font-weight: 700;
	   color: @disable-yellow;
	   margin: auto;
	}

	.ant-card-body {
		padding: 10px;
	}
	.ant-form-item-control-input-content > span {
		display: flex;
	}
}