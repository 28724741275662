@path-icon: '../../assets/icon/';

.current-path {
	min-height: 38px;
	padding: 9px 5px;
	border-bottom: 1px solid @disable-yellow;
	background-color: @pure-white;
	padding-left:24px;
	padding-right:24px;
	border-radius: 8px 8px 0px 0px;
	div:first-child {
		color: @grey;
		margin-left: 6px;
		margin-right: 5px;
		font-weight: bold;
		& + div {
			font-size: 13px;
			height: 26px;
			line-height: 23px;
		}
	}	
}

.custom-tree {
	height: 100%;
	.directory-tree {
		width: 100%;
		height: 630px;
		overflow: auto;
		white-space: nowrap;
	}
	.ant-spin-nested-loading {
		height: 100%;

		.ant-spin-container {
			height: 100%;
		}
	}
	.ant-tree { border-radius: 0px;	font-size: 13px;}
	.ant-tree-treenode {
		height: 32px;
		
	}

	div.ant-tree .ant-tree-treenode {
		align-items:flex-start;
		display: flex;
		padding: 0px;
	} 

	.ant-tree.ant-tree-directory .ant-tree-treenode::before {
		bottom: 0;
	}

	.ant-tree-switcher_close.ant-tree-switcher-icon svg {
		transform:none;
		-webkit-transform:none;
	}

	.ant-tree-switcher.ant-tree-switcher_open svg {
		transform:none;
		-webkit-transform:none;
		display: none;
	}

	.ant-tree-switcher.ant-tree-switcher_close svg {
		transform:none;
		-webkit-transform:none;
		display: none;
	}

	span.anticon.anticon-caret-down.ant-tree-switcher-icon svg {
		display: none;
	}

	.ant-tree span.ant-tree-node-content-wrapper {
		line-height:28px;
	}
	
	div.ant-tree-treenode.ant-tree-treenode-switcher-close {
		&> .ant-tree-switcher.ant-tree-switcher_close {
			mask: url('@{path-icon}plus-circle.svg') no-repeat  center;
			-webkit-mask: url('@{path-icon}plus-circle.svg') no-repeat  center;
			display: inline-block;
			mask-size: 12px;
			-webkit-mask-size: 12px;
			background: @main-yellow;
		}
	}

	div.ant-tree-treenode.ant-tree-treenode-switcher-open {
		&> .ant-tree-switcher.ant-tree-switcher_open {
			mask: url('@{path-icon}minus-circle.svg') no-repeat  center center;
			-webkit-mask: url('@{path-icon}minus-circle.svg') no-repeat  center center;
			display: inline-block;
			mask-size: 12px;
			-webkit-mask-size: 12px;
			background: @main-yellow;
		}
	}

	span.anticon.anticon-folder svg{
		display: none;
	}

	span.anticon.anticon-folder-open svg {
		display: none;
	}

	span.ant-tree-node-content-wrapper {
		&.ant-tree-node-content-wrapper-normal, &.ant-tree-node-content-wrapper-open {
			& > .ant-tree-icon__customize {			
			height: 31px;
			background: url('@{path-icon}folder-open.svg') no-repeat center center;
			}
		}
		&.ant-tree-node-content-wrapper-close > .ant-tree-icon__customize{
			height: 31px;
			background: url('@{path-icon}folder.svg') no-repeat center center;
		}
	}

	.ant-tree-switcher {
		margin-left: 25px;
		position: relative;
	}
	.ant-tree-title {
		margin-left: 5px;
		line-height: 33px;
		display: flex;
		color: @black;
	}

	div.ant-tree.ant-tree-directory .ant-tree-treenode{
		&:hover::before {
			background:  @light-yellow;
		}
		.ant-btn-link {
			color: @pure-black !important;
		}
		&:hover button {
			color: @main-yellow !important;
			transition-duration: 0.4s;
			font-weight: bold;
			opacity: 1;
		}
		
	}	

	div.ant-tree.ant-tree-directory .ant-tree-treenode-selected {
		&:hover::before, &::before  {
			background: @orange30;
		}
		& button, :hover button {
			&:hover {
				transition-duration: 0.4s;
				font-weight: bold;
			}
		}
	}

	.ant-tree.ant-tree-block-node .ant-tree-list-holder-inner .ant-tree-node-content-wrapper {
		display: flex;
	}
	.ant-tree-list-holder-inner {
		flex-wrap: wrap;
	}
}
