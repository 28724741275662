@import '~antd/dist/antd.less'; // Import Ant Design styles by less entry
@import '~antd/lib/style/themes/default.less';
@import './@styles/custom-theme.less'; // variables to override above
@import './@styles/font.less';

@main-yellow: #FCB034;
@disable-yellow: #FCB03480;
@secondary-yellow: #FDC467;
@light-yellow: #FEF4E9;
@light-blue: #FAFCFF;
@pure-white: #FFFFFF;
@pure-black: #231F20;
@error-red: #ff4d4f;
@grey: rgba(0, 0, 0, 0.4);
@default-black: rgba(0, 0, 0, 0.85);
@orange30: #FDDEBC;
@black10: #00000010;

@import './@styles/side-menu.less';
@import './@styles/search-form.less';
@import './@styles/table.less';
@import './@styles/button.less';
@import './@styles/confirm-modal.less';
@import './@styles/custom-tree.less';
@import './@styles/permission-form.less';
@import './@styles/custom-modal.less';
@import './@styles/input.less';
@import './@styles/download-service.less';
@import './@styles/directory-list.less';
@import './@styles/directory-preview.less';
@import './@styles/directory-download-config.less';

.error {
    color: @error-red;
    font-size: 12px;
}

button ~ button {
    margin-left: 10px;
}

div.ant-breadcrumb {
	margin: 15px 15px 0px 15px;
    font-size: 14px;
    font-weight: 500;
}

label.required:before {
    content: "* ";
    color: @error-red;
    font-size: 10px;
}

header.ant-layout-header {
    background-color: @pure-white;
    border-bottom: 4px solid @main-yellow;
    z-index: 10;
    height: 48px;
}

.ant-select-item-option-content
	& img ~ label {
    margin-left: 5px;
    display: inline-block;
    vertical-align: middle;
}

.ant-form-item-control-input-content>span {
    display: flex;
}

.ant-upload-list-item-info {
    width: 290px;
}

.button-set {
    justify-content: flex-end;
    margin-top: 15px;
}

#footer {
    padding: 0px;
    justify-content: center;
    display: flex;
    font-size: 12px;
    margin: 50px 0px 10px;
}

.ant-page-header-heading-left {
    .ant-page-header-heading-title {
        line-height: 40px;
        font-weight: 800;
        font-size: 22px;
        color: @pure-black;
    }
}

.properties-view {
    .ant-form-item {
        margin-bottom: 8px;
    }
}

.row-dragging {
	background: white;
	border: 1px solid @disable-yellow;
	font-size: 13px;
    z-index: 1000;
	.ant-table-cell {
		padding: 8px;
	} 
}

.search-form, #server-path-form,.current-path-form {
    input, .ant-input, .ant-picker, .ant-input-search-button, .ant-select:not(.ant-select-customize-input) .ant-select-selector, .ant-input-affix-wrapper,.ant-input-group-addon {
        border-radius: 4px;
    }
}

.back-to-set-portal {
	width: 255px;
	transition: all 0.2s;
	opacity: 1;
}

.back-to-set-portal-collapse {
	width: 0px;
	transition: all 0.2s;
	opacity: 0;
}

.ant-empty-description {
	font-size: 16px;
	font-weight: 600;
	color: @disable-yellow;
}

.ant-layout-content {
	.attention-table, .attention-approve-table {
		.ant-table-tbody>tr.ant-table-row:hover>td {
			cursor: unset;
		}
	}
	ul.ant-list-items li.ant-list-item {
		border-bottom: 1px solid #f0f0f0;
		&:hover {
			background: @light-yellow;
			cursor: pointer;
		}
	}
}

.ant-btn-link {
    &:not(:disabled) {
        color: @pure-black;
        &:hover {
            color: @main-yellow;
        }
    }
}

#footer {
    .ant-btn-link  {
        &:not(:disabled) {
            color: #009FC2;
        }
        &:hover>span {
            text-decoration: underline;
        }
    }
}


@font-family: 'Sarabun','DB HelvethaicaMon X',-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
        'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
        'Noto Color Emoji';@black: #231F20;@primary-color: #FCB034;@btn-default-ghost-bg: linear-gradient(180deg, #f2f2f3 0%, #e5e5e6 100%);@btn-default-ghost-border: 1px solid rgba(0, 0, 0, 0.06);@btn-primary-bg: linear-gradient(180deg, #fcca79 0%, #fcb94b 100%);@btn-shadow: none;@btn-primary-shadow: none;@btn-text-shadow: none;@btn-border-radius-base: 4px;@btn-font-weight: 500;@menu-item-active-bg: #FEF4E9;@breadcrumb-last-item-color: #231F20;@breadcrumb-link-color: #009FC2;@breadcrumb-link-color-hover: #009FC2;@breadcrumb-separator-color: #231F20;@input-placeholder-color: #00000040;@input-border-color: #00000015;@select-border-color: #00000015;@select-item-active-bg: #FEF4E9;@picker-border-color: #00000015;@picker-basic-cell-hover-color: #FEF4E9;@link-color: #009FC2;