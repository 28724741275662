.directory-download-config {
    border: 8px solid white;
    border-radius: 8px;
    .current-path-form {
        min-height: 38px;
        padding: 9px 10px 9px 5px;
        border-bottom: 1px solid @disable-yellow;
    }
   
    & .ant-form-item-label > label {
        color:@grey;
        font-weight: bold;
        font-size: 13px;
        line-height: 14px;
    }

    .ant-radio-checked {
        span.ant-radio-inner {
            border-width: 5px;
            &::after {
                width: 0
            }
        }
    }
}
