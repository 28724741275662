.directory-preview {
	border: 8px solid white;
    border-radius: 8px;
	.ant-row > span {
		font-size: 24px;
		font-weight: 700;
		color: @disable-yellow;
		margin: auto;
	 }
	.header-preview {
		margin-top: 10px;
		padding: 6px;
		height: 39px;
		color: @grey;
		font-weight: 500;
		border-bottom: 1px solid #f0f0f0;
	}
	.ant-table-container:before {
		box-shadow: none;
	}
	.ant-table-thead {
        .ant-table-cell {
            font-weight: 500;
            background-color: @pure-white !important;
            color: #00000066;
            &:not(.last-column) {
                border-right: 3px solid @pure-white !important;
            }
        }
    }
}