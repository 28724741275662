.download-service {
    border-radius: 8px !important;
	.ant-card-body {
		padding: 2px 24px;
	}
	.a:active, a:hover {
		text-decoration: underline;
	}
	.ant-row > span {
		font-size: 24px;
		font-weight: 700;
		color: @disable-yellow;
		margin: auto;
	 }
	.ant-tabs-tab-btn {
		font-weight: 700 !important;
		color: rgba(0, 0, 0, 0.4);
	}

	.ant-tabs-top > .ant-tabs-nav::before, .ant-tabs-bottom > .ant-tabs-nav::before, .ant-tabs-top > div > .ant-tabs-nav::before, .ant-tabs-bottom > div > .ant-tabs-nav::before {
		border-color: @disable-yellow;
	}

	.ant-table-selection-column {
		width: 20px !important;
		min-width: 0px !important;
		max-width: 0px !important;
	}
}